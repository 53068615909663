<template>
  <transition name="fade">
    <section
      ref="playerInterface"
      class="interface-section"
      :class="{ isFullScreenCta: isFullScreen }"
    >
      <!-- all -->
      <player-title v-show="Boolean(title) && showTitle">
        {{ decodeTextPlayer(title) }}
      </player-title>

      <vidjet-watermark v-if="showWatermark"> </vidjet-watermark>
      <button
        v-if="showShareButton"
        v-tooltip="{
          text: $t('player.linkCopied'),
          trigger: 'click',
          position: 'left',
        }"
        class="share-button"
        :class="{
          'share-button-inline-carousel': !isFullScreen && isCarousel,
          'share-button-non-fullscreen': !isFullScreen && !isCarousel,
        }"
        @click="shareWebPage"
      >
        <Share />
      </button>
      <div
        v-if="cta && Object.keys(cta).length && isCtaWithinRange"
        class="call-to-action"
        :class="callToActionClasses"
        ref="callToAction"
        :style="bottomMargin"
      >
        <cta-button
          v-if="isCtaButton"
          :video-index="videoIndex"
          @pause-video="$emit('pause-video')"
        >
          {{ decodeTextPlayer(cta.text) }}
        </cta-button>

        <div
          class="atc-button-wrapper"
          :class="[
            {
              centered: !isAtcMultipleProducts,
              animate: animationStates[videoIndex],
              'carousel-inline-atc-button-wrapper': isCarousel && !isFullScreen,
              'inline-atc-button-wrapper':
                !isFullScreen && !isCarousel && !isBubbleAndLargeScreen,
              'bubble-inline-atc-button-wrapper':
                isBubble && !isFullScreen && !isBubbleAndLargeScreen,
            },
          ]"
          ref="atcButtonWrapper"
          v-else-if="isATCButton && !isAtcOpened"
        >
          <div
            class="atc-button-container"
            :class="[
              {
                'atc-button-container-mobile':
                  (isMobile || !isFullScreen) && !isBubbleAndLargeScreen,
              },
              { dragging: isDragging },
              {
                'atc-fullscreen-arrows':
                  (hasArrows && isFullScreen) ||
                  (hasArrows && isBubbleAndLargeScreen),
                'carousel-inline-had-arrows':
                  !isFullScreen && hasArrows && !isBubbleAndLargeScreen,
              },
            ]"
            ref="atcButtonContainer"
            @mousedown.stop="startDrag"
            @touchstart="startDrag"
            @mousemove="handleDrag"
            @touchmove="handleDrag"
            @mouseup="endDrag"
            @touchend="endDrag"
            @mouseleave="endDrag"
          >
            <add-to-cart-button
              v-for="(product, index) in cta.products"
              v-bind:key="product._id"
              class="product-card"
              :class="{
                'product-card-inline-has-arrows':
                  !isFullScreen && hasArrows && !isBubbleAndLargeScreen,
              }"
              :ref="`atcButton-${index}`"
              @click="toggleAtcFormVisibility(product, index)"
              :product="product"
              :has-arrows="hasArrows"
            >
              <template v-slot:left-arrow>
                <div
                  v-show="
                    hasArrows && index > 0 && currentButtonIndex === index
                  "
                  class="arrow-between-buttons left-arrow"
                  :class="{
                    'inline-arrow': !isFullScreen && !isBubbleAndLargeScreen,
                    'inline-left-arrows': !isFullScreen,
                  }"
                  @click.stop="scrollPrevWithArrow"
                >
                  <ArrowHorizontal
                    v-if="isFullScreen || isBubbleAndLargeScreen"
                    class="horizontal-arrow"
                  />
                  <ArrowHorizontalSmall v-else class="horizontal-arrow" />
                </div>
              </template>
              <template v-slot:right-arrow>
                <div
                  v-show="
                    hasArrows &&
                    index < cta.products.length - 1 &&
                    currentButtonIndex === index
                  "
                  class="arrow-between-buttons right-arrow"
                  :class="{
                    'inline-arrow': !isFullScreen && !isBubbleAndLargeScreen,
                    'inline-right-arrows': !isFullScreen,
                  }"
                  @click.stop="scrollNextWithArrow"
                >
                  <ArrowHorizontal
                    v-if="isFullScreen"
                    class="horizontal-arrow right-arrow"
                  />
                  <ArrowHorizontalSmall
                    v-else
                    class="horizontal-arrow right-arrow"
                  />
                </div>
              </template>
            </add-to-cart-button>
          </div>
        </div>

        <custom-input v-else-if="isEmailCollector" :videoIndex="videoIndex">
        </custom-input>
      </div>
      <transition name="fade">
        <div v-if="showAtcForm" class="atc-form">
          <AtcForm
            v-click-outside="toggleAtcFormVisibility"
            :video-index="videoIndex"
            :isManucurist="isManucurist"
            :product="
              cta.products[
                productButtonClicked !== null ? productButtonClicked : 0
              ]
            "
            @close="toggleAtcFormVisibility()"
            @added-to-cart="hideAtcForm"
          ></AtcForm>
        </div>
      </transition>
      <div
        v-if="showSwipeIndication && isMultiple && !isHorizontalSwipe"
        class="swiping-indication"
      >
        <Arrow />
        <Arrow />
      </div>
    </section>
  </transition>
</template>

<script>
import enums from "@/enums.js";
import { decodeText } from "../utils/utils";

import { mapGetters, mapActions, mapMutations } from "vuex";

const { CtaType, FormatType } = enums;

export default {
  name: "PlayerInterface",

  components: {
    AddToCartButton: () => import("./player-components/AddToCartButton.vue"),
    VidjetWatermark: () => import("./player-components/VidjetWatermark.vue"),
    PlayerTitle: () => import("./player-components/PlayerTitle.vue"),
    CtaButton: () => import("./player-components/CtaButton.vue"),
    CustomInput: () => import("./player-components/CustomInput.vue"),
    Share: () => import("@/assets/share.svg"),
    AtcForm: () => import("./add-to-cart/AtcForm.vue"),
    Arrow: () => import("@/assets/arrow.svg"),
    ArrowHorizontal: () => import("@/assets/arrow-horizontal.svg"),
    ArrowHorizontalSmall: () =>
      import("@/assets/horizontal-right-arrow-small.svg"),
  },

  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
    showControls: {
      type: Boolean,
      default: true,
    },
    isCtaWithinRange: {
      type: Boolean,
      default: true,
    },
    videoIndex: {
      type: Number,
      default: 0,
    },
    isCurrentVideo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      redirectAfterClick: false,
      isAtcFormVisible: false,
      products: [],
      FormatType,
      CtaType,
      isManucurist: false,
      showSwipeIndication: false,
      indicationHasBeenShown: false,
      animationStates: {},
      startX: 0,
      scrollLeft: 0,
      isDragging: false,
      currentButtonIndex: 0,
    };
  },

  mounted() {
    this.cta?.products?.forEach((product) => {
      if (
        product.siteId === "81b984f5-5bb6-437b-9c00-50f028c40076" ||
        product.siteId === "39f362e5-c3f4-4eba-bdfd-2b9be89f827d" ||
        // test player site id
        product.siteId === "874f71d2-a54d-4830-9941-f4e30c237560"
      ) {
        this.isManucurist = true;
      }
    });
    // show swipe indication only if video is multiple and not carousel inline
    if (this.isMultiple && !(this.isCarousel && !this.isFullScreen)) {
      this.showSwipeIndication = true;
    }
    this.isPlayerOpened &&
      this.showSwipeIndication &&
      this.handleSwipingIndication();
    if (this.isCurrentVideo) {
      this.triggerAnimation();
    }
  },

  computed: {
    ...mapGetters("playerCampaign", [
      "format",
      "isEmbed",
      "isBubble",
      "isCarousel",
      "isFullScreen",
      "position",
      "isHorizontalSwipe",
    ]),
    ...mapGetters("videos", [
      "isMultiple",
      "isPortrait",
      "isLandscape",
      "isSquare",
      "getCtaByIndex",
      "currentIndex",
    ]),
    ...mapGetters("analytics", ["hasFlowId", "getUrl"]),
    ...mapGetters("player", [
      "isTablet",
      "isMobile",
      "isInPreview",
      "isPlayerOpened",
      "isAtcOpened",
    ]),
    ...mapGetters("playerSite", ["site", "showWatermark"]),

    isAtcMultipleProducts() {
      return (this.isATCButton && this.cta?.products?.length > 1) ?? false;
    },

    cta() {
      return this.getCtaByIndex(this.videoIndex);
    },

    showShareButton() {
      return (
        !this.isAtcOpened &&
        !(this.isMobile && this.isFullScreen && this.isLandscape) &&
        !this.format.hideControlBar
      );
    },

    // don’t show btn on Fullscreen desktop
    isATCButton() {
      return (
        this.cta?.ctaType === CtaType.product &&
        (!this.isFullScreen || this.isMobile || this.isHorizontalSwipe)
      );
    },

    title() {
      return this.format.title;
    },

    bottomMargin() {
      return `bottom: ${
        this.cta.bottomMarginPx && this.cta.bottomMarginPx !== 0
          ? this.cta.bottomMarginPx + 25
          : 25
      }px`;
    },

    isEmailCollector() {
      return this.cta?.ctaType === CtaType.input;
    },

    isCtaButton() {
      return ![CtaType.input, CtaType.product].includes(this.cta?.ctaType);
    },

    buttonColors() {
      return {
        buttonColor: this.cta?.buttonColor,
        textColor: this.cta?.textColor,
      };
    },

    showAtcForm() {
      return this.isAtcFormVisible && this.isATCButton && this.isCtaWithinRange;
    },
    hasArrows() {
      return this.format?.productCardOptions?.transition === "arrows";
    },
    isBubbleAndLargeScreen() {
      return this.isBubble && !this.isInPreview && window.innerWidth >= 400;
    },
    isBubbleAndSmall() {
      return this.isBubble && window.innerWidth <= 350;
    },
    isBubbleAndSmaller() {
      return this.isBubble && window.innerWidth <= 325;
    },
    isBubbleAndVerySmall() {
      return this.isBubble && window.innerWidth <= 300;
    },
    isBubbleSuperSmall() {
      return this.isBubble && window.innerWidth <= 275;
    },
    callToActionClasses() {
      return {
        "call-to-action-atc": this.isATCButton,
        "call-to-action-atc-inline-carousel":
          this.isCarousel && !this.isFullScreen,
        "call-to-action-atc-inline-carousel-with-arrows":
          this.isCarousel && !this.isFullScreen && this.hasArrows,
        "call-to-action.call-to-action-atc-inline-carousel-preview":
          this.isCarousel &&
          !this.isFullScreen &&
          this.hasArrows &&
          this.isInPreview,
        "call-to-action-atc-fullscreen-mobile":
          this.isFullScreen && this.isATCButton && this.isMobile,
        "call-to-action-atc-fullscreen-horizontal":
          this.isFullScreen &&
          this.isATCButton &&
          !this.isMobile &&
          this.isHorizontalSwipe,
        "call-to-action-atc-bubble-big-arrows":
          this.isBubbleAndLargeScreen && this.isATCButton,
        "call-to-action-atc-inline-bubble":
          this.isBubble && !this.isFullScreen && !this.isBubbleAndLargeScreen,
        "call-to-action-atc-inline-bubble-preview":
          this.isBubble &&
          !this.isFullScreen &&
          !this.isBubbleAndLargeScreen &&
          this.isInPreview,
        "call-to-action-atc-inline-bubble-with-arrows":
          this.isBubble &&
          !this.isFullScreen &&
          !this.isBubbleAndLargeScreen &&
          this.isATCButton &&
          this.hasArrows &&
          this.isAtcMultipleProducts,
      };
    },
  },

  watch: {
    isATCButton(newVal) {
      if (!newVal) {
        this.toggleAtc(false);
      }
    },
    isPlayerOpened(newVal) {
      newVal && this.showSwipeIndication && this.handleSwipingIndication();
    },
    isCurrentVideo(newVal) {
      if (newVal === true) {
        const atcButtonWrapper = this.$refs.atcButtonWrapper;
        if (atcButtonWrapper) {
          atcButtonWrapper.classList.remove("animate");
        }
        if (this.isAtcFormVisible) {
          this.toggleAtcFormVisibility();
        }
        // Trigger animation
        this.triggerAnimation();
      } else {
        if (this.isAtcFormVisible) {
          this.toggleAtcFormVisibility();
        }
        // Reset animation state for the current video index
        this.$set(this.animationStates, this.videoIndex, false);
      }
    },
  },

  methods: {
    ...mapActions({
      sendEvent: "analytics/sendEvent",
    }),

    ...mapMutations({
      toggleAtc: "player/TOGGLE_ATC",
    }),

    toggleAtcFormVisibility(product, index) {
      this.findIndexAtcButtonClicked(index);
      this.isAtcFormVisible = !this.isAtcFormVisible;
      this.currentButtonIndex = 0;
      if (this.isAtcFormVisible) {
        this.$emit("pause-video");
      }

      if (product?._id !== "") {
        this.toggleAtc(!this.isAtcOpened);
      } else if (this.isInPreview) {
        this.$emit("notify-preview");
      }
    },

    findIndexAtcButtonClicked(index) {
      if (!index) {
        this.productButtonClicked = null;
      } else {
        this.productButtonClicked = index;
      }
    },

    decodeTextPlayer(string) {
      return decodeText(string);
    },

    hideAtcForm() {
      // use prop sync to hide atc from component
      setTimeout(() => {
        this.isAtcOpened && this.toggleAtcFormVisibility();
      }, 2000);
    },

    shareWebPage() {
      let url = new URL(this.getUrl);
      // fall back value
      url ??= document.referrer;
      const campaignName = this.format.name;
      const params = {
        utm_source: "vidjet-video",
        utm_campaign: campaignName,
        utm_medium: "video",
      };
      url.search =
        (url.search ? url.search + "&" : "") + new URLSearchParams(params);
      navigator.clipboard.writeText(url.toString());
      if (!this.isInPreview) {
        this.sendEvent({
          type: "sharelinkCopy",
        });
      }
    },

    handleSwipingIndication() {
      this.indicationHasBeenShown = true;
      setTimeout(() => {
        this.showSwipeIndication = false;
      }, 5000);
    },
    startDrag(e) {
      if (this.hasArrows) return;
      this.isDragging = true;
      this.startX = e.clientX || e.touches[0].clientX;
      this.scrollLeft = this.$refs.atcButtonContainer.scrollLeft;
    },

    handleDrag(e) {
      if (!this.isDragging) return;
      const x = e.clientX || e.touches[0].clientX;
      const walk = (x - this.startX) * 2;
      this.$refs.atcButtonContainer.scrollLeft = this.scrollLeft - walk;
    },

    endDrag() {
      this.isDragging = false;
    },

    triggerAnimation() {
      setTimeout(() => {
        this.$set(this.animationStates, this.videoIndex, true);
      }, 700);
    },
    scrollNextWithArrow() {
      this.currentButtonIndex += 1;
      const container = this.$refs.atcButtonContainer;

      if (this.$refs.atcButtonContainer) {
        const scrollAmount =
          this.isFullScreen || this.isBubbleAndLargeScreen ? 200 : 100;
        container.scrollLeft += scrollAmount;
      }
    },
    scrollPrevWithArrow() {
      this.currentButtonIndex -= 1;
      const container = this.$refs.atcButtonContainer;

      if (container) {
        const scrollAmount =
          this.isFullScreen || this.isBubbleAndLargeScreen ? 200 : 100;
        container.scrollLeft -= scrollAmount;
      }
    },
  },
};
</script>

<style>
/* make scrollbar hidden only on element with class atc-button-wrapper */

.atc-button-wrapper::-webkit-scrollbar {
  display: none;
}
</style>

<style lang="scss" scoped>
$button-position: 55px;
$button-position-landscape: 22%;
$button-position-portrait: 20%;

.interface-section {
  display: flex;
  height: 100%;
  width: 100%;
}

.call-to-action {
  z-index: 5;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  display: flex;
  max-width: 100%;
}

.call-to-action.call-to-action-atc-inline-carousel {
  width: 86px;
}

.atc-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 16px;
  margin: 0 auto;
  scrollbar-width: none;
  overflow-x: scroll;
  padding-right: 24px;
  filter: drop-shadow(2px 3px 3px rgba(0, 0, 0, 0.8));
}

.carousel-inline-atc-button-wrapper {
  padding-right: 0;
  width: 100%;
}

.atc-button-wrapper .atc-button-container-mobile {
  gap: 12px;
}
.centered {
  justify-content: center;
  overflow-y: hidden;
}
.atc-form {
  z-index: $index-icon-position;
  width: 97%;
  max-height: 95%;
  display: flex;
  position: absolute;
  right: 1.5%;
  left: 1.5%;
  box-sizing: border-box;
  bottom: 5px;
  max-width: 250px;
}

.isLandscape .atc-form {
  max-width: 350px;
}

.isFullScreen .atc-form {
  max-width: none;
  max-height: none;
}

.isFullScreen.isHorizontalSwipe .atc-form {
  max-height: 90%;
}

.isMobile.isFullScreen .atc-form {
  max-height: calc(var(--vh, 1vh) * 90);
}

.isDesktop {
  .share-button:hover {
    transform: scale(1.1);
    transition: $button-transition;
  }
}

.share-button {
  position: absolute;
  top: $button-position;
  width: 30px;
  height: 30px;
  right: 16px;
  z-index: $index-icon-position;
  background: $transparent-black;
  cursor: pointer;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    transform: scale(1.2);
  }
}
.share-button-inline-carousel {
  top: 25px;
  right: 8px;
}
.share-button-non-fullscreen {
  right: 8px;
}
.isPortrait {
  .share-button {
    bottom: $button-position;
  }
}

.isFullScreen {
  .share-button {
    height: 40px;
    width: 40px;
    top: 64px;

    svg {
      transform: scale(1.5);
    }
  }
}
.isFullScreen.isHorizontalSwipe {
  .share-button {
    height: 30px;
    width: 30px;
    top: 64px;

    svg {
      transform: scale(1.2);
    }
  }
}

.swiping-indication {
  position: absolute;
  bottom: 1%;
  margin: auto;
  transform: scale(1.5) rotate(180deg) translateX(-50%);
  right: 50%;
  z-index: 25;
  display: flex;
  flex-direction: column;
  svg {
    fill: white;
  }
  // make css animation with the two svg to indicate the video is swipeable
  svg:first-child {
    animation: swipe 2s infinite;
  }
  svg:last-child {
    animation: swipe 2s infinite;
    animation-delay: 1s;
  }
}

.isFullScreen .swiping-indication {
  bottom: 5%;
  // 16.6% because scale 3 => 50/3 = 16.6%
  transform: scale(3) rotate(180deg) translateX(-16.6%);
}
@keyframes swipe {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.atc-button-container {
  display: flex;
  gap: 16px;
  overflow: hidden;
  scroll-snap-type: x mandatory;
  transition: scroll-left 0.3s ease;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  position: relative;
}
.atc-button-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.atc-button-container::-webkit-scrollbar-track {
  background-color: transparent;
}
.atc-button-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.atc-button-container.dragging {
  transition: none;
}

.product-card {
  flex: 0 0 auto;
  scroll-snap-align: start;
  position: relative;
  // width: 100%;
}
.atc-button-wrapper {
  .product-card {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 1s ease, transform 0.5s ease;
  }
}
.atc-button-wrapper.animate .product-card {
  opacity: 1;
  transform: translateY(0);
}

.inline-atc-button-wrapper {
  width: 75%;
  justify-content: center;
  margin: 0 auto;
  padding-right: 0;
}
.bubble-inline-atc-button-wrapper {
  width: 100%;
}
.left-arrow {
  left: 0px;
  transform: rotate(180deg);
}
.right-arrow {
  right: 0px;
}
.arrow-between-buttons {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.4);
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 100;
  backdrop-filter: blur(500px);
}

.inline-arrow {
  bottom: 17px;
  width: 24px;
  height: 24px;
}

.horizontal-arrow {
  transform: scale(0.6);
  margin-left: 3px;
  path {
    fill: rgba(88, 88, 88, 1);
  }
}
.atc-fullscreen-arrows {
  gap: 48px !important;
}

.call-to-action-atc-fullscreen-mobile {
  left: 12%;
  right: 12%;
  transform: translateX(0);
  width: 310px;
}

.call-to-action-atc-fullscreen-horizontal {
  left: 5%;
  right: 5%;
  transform: translateX(0);
  width: 310px;
}

.call-to-action-atc-bubble-big-arrows {
  left: 15%;
  right: 15%;
  transform: translateX(0);
  .atc-button-wrapper {
    padding-right: 0;
  }
}
.call-to-action-atc-inline-bubble {
  width: 86px;
}

.call-to-action-atc-inline-bubble-preview {
  width: 100px;
}

.call-to-action-atc-inline-bubble-with-arrows {
  justify-content: center;
  align-items: center;
}

.atc-button-wrapper .carousel-inline-had-arrows {
  gap: 30px;
}

.product-card-inline-has-arrows {
  display: flex;
  justify-content: flex-start;
}

.call-to-action.call-to-action-atc-inline-carousel-preview {
  width: 100px;
}

</style>
